import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import styles from './index.module.less';

const PageHeader = props => (
  <header className={styles.root}>
    <div className={styles.image}>
      <div className={styles.titleWrapper}>
        <Grid fluid>
          <Row>
            <Col xs={12}>
              <h1 className={styles.title}>{props.title}</h1>
            </Col>
          </Row>
        </Grid>
      </div>
    </div>
    <div className={styles.introWrapper}>
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <p className={styles.intro}>{props.intro}</p>
          </Col>
        </Row>
      </Grid>
    </div>
  </header>
);

export default PageHeader;
