import React from 'react'
import Link from 'gatsby-link'
import Helmet from 'react-helmet'
import { Grid, Row, Col } from 'react-flexbox-grid'
import PageHeader from 'components/PageHeaderHero'
import {
  Title2,
  Title3,
  Paragraph,
  List,
  ListItem,
} from 'components/Typography'
import Button from 'components/Button'
import styles from './index.module.less'
import before1 from 'images/before-1.png'
import after1 from 'images/after-1.png'
import before2 from 'images/before-2.png'
import after2 from 'images/after-2.png'
import supporter1 from 'images/supporter-lt-mcguinness.png'
import supporter2 from 'images/supporter-dulux.png'
import supporter3 from 'images/supporter-nme.png'
import supporter4 from 'images/supporter-geeves-scaffolding.png'
import supporter5 from 'images/supporter-carters.png'
import supporter6 from 'images/supporter-metalcraft-roofing.png'
import project1 from 'images/project-1b.png'
import project2 from 'images/project-2c.png'
import project3 from 'images/project-3c.png'
import project4 from 'images/project-4b.png'
import project5 from 'images/project-5b.png'
import project6 from 'images/project-6a.png'
import project7 from 'images/project-7a.png'
import project8 from 'images/project-8d.png'
import project9 from 'images/project-9a.png'
import project10 from 'images/project-10a.png'
import project11 from 'images/project-11a.png'
import project12 from 'images/project-12f.png'
import project13 from 'images/project-13a.png'
import project14 from 'images/project-14a.png'
import project15 from 'images/project-15a.png'
import project16 from 'images/project-16a.png'
import project17 from 'images/project-17a.png'
import project18 from 'images/project-18a.png'
import project19 from 'images/project-19a.png'
import project20 from 'images/project-20a.png'
import project21 from 'images/project-21a.png'
import Layout from 'components/Layout'

const Page = () => (
  <Layout>
    <Helmet
      title="Ryhaven Charitable Trust: Affordable Home Repairs"
      meta={[
        {
          name: 'description',
          content:
            'Ryhaven Charitable Trust assists limited income home owners to repair and upgrade their home.',
        },
      ]}
    >
      <html lang="en" />
    </Helmet>
    <PageHeader
      title="Need home repairs, but struggling to afford them?"
      intro="Ryhaven Charitable Trust assists limited income home owners to repair and upgrade their home."
      bgImage
    />
    <section className={styles.services}>
      <Grid fluid className={styles.servicesInner}>
        <Row>
          <Col xs={12}>
            <Title2>How we can help</Title2>
          </Col>
        </Row>
        <Row>
          <Col sm={6} md={4}>
            <section className={styles.servicesSection}>
              <Title3>Indoor</Title3>
              <List>
                <ListItem>Kitchen improvements</ListItem>
                <ListItem>Bathroom repairs</ListItem>
                <ListItem>Modifications for disabled</ListItem>
                <ListItem>Mould &amp; damp repair</ListItem>
                <ListItem>Plumbing &amp; electric upgrades</ListItem>
              </List>
            </section>
            <section className={styles.servicesSection}>
              <Title3>Outdoor</Title3>
              <List>
                <ListItem>Exterior repairs & painting</ListItem>
                <ListItem>Roof repairs or replacement</ListItem>
                <ListItem>Disability ramps</ListItem>
              </List>
            </section>
            <section className={styles.servicesSection}>
              <Title3>Temporary Accomodation</Title3>
              <List>
                <ListItem>Cabin hire</ListItem>
                <ListItem>Caravan hire</ListItem>
              </List>
            </section>
          </Col>
          <Col sm={6} md={8}>
            <Row>
              <Col xs={12} sm={6}>
                <figure className={styles.figure}>
                  <img className={styles.image} src={before1} alt="" />
                  <figcaption>Before</figcaption>
                </figure>
              </Col>
              <Col xs={12} sm={6}>
                <figure className={styles.figure}>
                  <img className={styles.image} src={after1} alt="" />
                  <figcaption>After</figcaption>
                </figure>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6}>
                <figure className={styles.figure}>
                  <img className={styles.image} src={before2} alt="" />
                  <figcaption>Before</figcaption>
                </figure>
              </Col>
              <Col xs={12} sm={6}>
                <figure className={styles.figure}>
                  <img className={styles.image} src={after2} alt="" />
                  <figcaption>After</figcaption>
                </figure>
              </Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    </section>

    <section className={styles.quotes}>
      <Grid fluid className={styles.quotesInner}>
        <Row>
          <Col xs={12}>
            <Title2>What people say</Title2>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <blockquote className={styles.quote}>
              &ldquo;The staff involved were respectful and did more than expected, thinking of things that would improve my life. &rdquo;
            </blockquote>
            <p className={styles.quoteAuthor}>Amanda, Titahi Bay</p>
          </Col>
          <Col sm={4}>
            <blockquote className={styles.quote}>
              &ldquo;We have learnt so much about renovating and how to fix things. Knowing there are other families waiting to have their houses done has given us an extra push… so that other families can be blessed with the same overwhelming happiness as us.&rdquo;
            </blockquote>
            <p className={styles.quoteAuthor}>Whanāu member, Porirua</p>
          </Col>
          <Col sm={4}>
            <blockquote className={styles.quote}>
              &ldquo;With the new lease of life that has been given to the family home, it will finally become a home again with lots of visitors and laughter – just as a home should be. &rdquo;
            </blockquote>
            <p className={styles.quoteAuthor}>Felila, Porirua</p>
          </Col>
        </Row>
      </Grid>
    </section>

    <section className={styles.steps}>
      <Grid fluid className={styles.stepsInner}>
        <Row>
          <Col xs={12}>
            <Title2>How does it work?</Title2>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <section className={styles.step}>
              <Title3>1. Apply</Title3>
              <Paragraph>
                Complete our online application form. Applications are welcomed
                from Wellington homeowners of limited income.
              </Paragraph>
              <Button to="/apply">Apply</Button>
            </section>
          </Col>
          <Col sm={4}>
            <section className={styles.step}>
              <Title3>2. Assess your needs</Title3>
              <Paragraph>
                If your application is successful, the trust will determine the
                appropriate assistance required. Needs are assessed on a
                case-by-case basis.
              </Paragraph>
            </section>
          </Col>
          <Col sm={4}>
            <section className={styles.step}>
              <Title3>3. Work starts</Title3>
              <Paragraph>
                The repairs are completed by a combination of Ryhaven staff,
                volunteers, licensed contractors and where possible the families
                of homeowners.
              </Paragraph>
            </section>
          </Col>
        </Row>
      </Grid>
    </section>

    <section className={styles.projects}>
      <Grid fluid className={styles.projectsInner}>
        <Row>
          <Col xs={12}>
            <Title2>Recent projects</Title2>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <section className={styles.project}>
              <Link to="/recent-projects/project-17">
                <img
                  className={styles.image}
                  src={project17}
                  alt="View project"
                />
              </Link>
              <Title3>Water, water, everywhere</Title3>
              <Paragraph>
                When a local family&rsquo;s lounge floor collapsed, they didn&rsquo;t know what to do and urgently reached out to the Ryhaven Trust for help.
              </Paragraph>
              <Button to="/recent-projects/project-17">View project</Button>
            </section>
          </Col>
          <Col sm={4}>
            <section className={styles.project}>
              <Link to="/recent-projects/project-18">
                <img
                  className={styles.image}
                  src={project18}
                  alt="View project"
                />
              </Link>
              <Title3>Much-loved home brought &lsquo;back to life&rsquo;</Title3>
              <Paragraph>
                The Trust was approached to help a Porirua family with a home in need of desperate repair.
              </Paragraph>
              <Button to="/recent-projects/project-18">View project</Button>
            </section>
          </Col>
          <Col sm={4}>
            <section className={styles.project}>
              <Link to="/recent-projects/project-19">
                <img
                  className={styles.image}
                  src={project19}
                  alt="View project"
                />
              </Link>
              <Title3>The impossible dream</Title3>
              <Paragraph>
                Following years of serious ceiling leaks, a Wellington family home had become practically uninhabitable.
              </Paragraph>
              <Button to="/recent-projects/project-19">View project</Button>
            </section>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <section className={styles.project}>
              <Link to="/recent-projects/project-20">
                <img
                  className={styles.image}
                  src={project20}
                  alt="View project"
                />
              </Link>
              <Title3>Looking smart and on the level</Title3>
              <Paragraph>
                When the trust was asked to check out at an &lsquo;un-level&rsquo; floor in a local home, the team soon uncovered a more troublesome issue.
              </Paragraph>
              <Button to="/recent-projects/project-20">View project</Button>
            </section>
          </Col>
          <Col sm={4}>
            <section className={styles.project}>
              <Link to="/recent-projects/project-21">
                <img
                  className={styles.image}
                  src={project21}
                  alt="View project"
                />
              </Link>
              <Title3>Building affordable rentals</Title3>
              <Paragraph>
                In the Hutt Valley, two new Waiwhetu townhouses are under construction to offer affordable rental accommodation for low-income families.
              </Paragraph>
              <Button to="/recent-projects/project-21">View project</Button>
            </section>
          </Col>
          <Col sm={4}>
            <section className={styles.project}>
              <Link to="/recent-projects/project-16">
                <img
                  className={styles.image}
                  src={project16}
                  alt="View project"
                />
              </Link>
              <Title3>Elsdon home upgrade &mdash; Feeling like &lsquo;a home again&rsquo;</Title3>
              <Paragraph>
                In late 2020, the Trust took on a deferred maintenance project for an elderly homeowner.
              </Paragraph>
              <Button to="/recent-projects/project-16">View project</Button>
            </section>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <section className={styles.project}>
              <Link to="/recent-projects/project-15">
                <img
                  className={styles.image}
                  src={project15}
                  alt="View project"
                />
              </Link>
              <Title3>Titahi Bay lifesaver</Title3>
              <Paragraph>
                The Trust assisted a family living in Porirua&rsquo;s Titahi Bay to paint their home and carry out some deferred maintenance.
              </Paragraph>
              <Button to="/recent-projects/project-15">View project</Button>
            </section>
          </Col>
          <Col sm={4}>
            <section className={styles.project}>
              <Link to="/recent-projects/project-14">
                <img
                  className={styles.image}
                  src={project14}
                  alt="View project"
                />
              </Link>
              <Title3>Whānau roll up their sleeves</Title3>
              <Paragraph>
                In December 2020, the Trust helped a local Porirua family to renovate their home.
              </Paragraph>
              <Button to="/recent-projects/project-14">View project</Button>
            </section>
          </Col>
          <Col sm={4}>
            <section className={styles.project}>
              <Link to="/recent-projects/project-13">
                <img
                  className={styles.image}
                  src={project13}
                  alt="View project"
                />
              </Link>
              <Title3>Affordable family rentals</Title3>
              <Paragraph>
                In 2020, the Ryhaven Trust assisted a family to relocate and relevel their house on a large section in Naenae.
              </Paragraph>
              <Button to="/recent-projects/project-13">View project</Button>
            </section>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <section className={styles.project}>
              <Link to="/recent-projects/project-12">
                <img
                  className={styles.image}
                  src={project12}
                  alt="View project"
                />
              </Link>
              <Title3>Financial future looking bright</Title3>
              <Paragraph>
                A husband and wife struggling to pay their debts feared they would
                eventually have to sell their home.
              </Paragraph>
              <Button to="/recent-projects/project-12">View project</Button>
            </section>
          </Col>
          <Col sm={4}>
            <section className={styles.project}>
              <Link to="/recent-projects/project-11">
                <img
                  className={styles.image}
                  src={project11}
                  alt="View project"
                />
              </Link>
              <Title3>Exterior upgrade and landscaping</Title3>
              <Paragraph>
                For a solo mother and her 11-year-old son, a home and landscape
                makeover has made the world of difference.
              </Paragraph>
              <Button to="/recent-projects/project-11">View project</Button>
            </section>
          </Col>
          <Col sm={4}>
            <section className={styles.project}>
              <Link to="/recent-projects/project-10">
                <img
                  className={styles.image}
                  src={project10}
                  alt="View project"
                />
              </Link>
              <Title3>Whānau lend a helping hand</Title3>
              <Paragraph>
                Dealing with the demands of a growing family and a home in need of
                critical repair, this local Stokes Valley family reached out to
                the Ryhaven Trust for urgent support.
              </Paragraph>
              <Button to="/recent-projects/project-10">View project</Button>
            </section>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <section className={styles.project}>
              <Link to="/recent-projects/project-9">
                <img className={styles.image} src={project9} alt="View project" />
              </Link>
              <Title3>Rental cabins and caravans – an affordable option</Title3>
              <Paragraph>
                Families in need of more space have benefited from our temporary,
                affordable rentals.
              </Paragraph>
              <Button to="/recent-projects/project-9">View project</Button>
            </section>
          </Col>
          <Col sm={4}>
            <section className={styles.project}>
              <Link to="/recent-projects/project-8">
                <img className={styles.image} src={project8} alt="View project" />
              </Link>
              <Title3>Safe haven</Title3>
              <Paragraph>
                Sharron is the primary caregiver for her special needs grandson
                and when her landlord gave her notice to move out she felt she was
                &lsquo;out of options&rsquo;.
              </Paragraph>
              <Button to="/recent-projects/project-8">View project</Button>
            </section>
          </Col>
          <Col sm={4}>
            <section className={styles.project}>
              <Link to="/recent-projects/project-7">
                <img className={styles.image} src={project7} alt="View project" />
              </Link>
              <Title3>What a difference a roof makes!</Title3>
              <Paragraph>
                Aaron and his wife bought their Wellington home seventeen years
                ago. Whilst it represented &lsquo;home&rsquo; and security for
                their three-generation family, the old house was in need of
                maintenance.
              </Paragraph>
              <Button to="/recent-projects/project-7">View project</Button>
            </section>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <section className={styles.project}>
              <Link to="/recent-projects/project-6">
                <img className={styles.image} src={project6} alt="View project" />
              </Link>
              <Title3>Feels like a new home</Title3>
              <Paragraph>
                Before Trevor&rsquo;s daughter Jane approached Ryhaven Trust,
                Trevor believed that the only option for his aging home was to
                bulldoze it.
              </Paragraph>
              <Button to="/recent-projects/project-6">View project</Button>
            </section>
          </Col>
          <Col sm={4}>
            <section className={styles.project}>
              <Link to="/recent-projects/project-5">
                <img className={styles.image} src={project5} alt="View project" />
              </Link>
              <Title3>Dulux Adds some Shine to Taita Home</Title3>
              <Paragraph>
                Friday 19 October was anything but a day in the office for Dulux
                CEO Jevan Dickinson and an enthusiastic team of sixteen Dulux
                staff volunteers.
              </Paragraph>
              <Button to="/recent-projects/project-5">View project</Button>
            </section>
          </Col>
          <Col sm={4}>
            <section className={styles.project}>
              <Link to="/recent-projects/project-4">
                <img className={styles.image} src={project4} alt="View project" />
              </Link>
              <Title3>Home now safe and secure</Title3>
              <Paragraph>
                Leaks, electric shocks and roof in bad state of repair were just
                some of the problems affecting this family home.
              </Paragraph>
              <Button to="/recent-projects/project-4">View project</Button>
            </section>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <section className={styles.project}>
              <Link to="/recent-projects/project-3">
                <img className={styles.image} src={project3} alt="View project" />
              </Link>
              <Title3>Cabins provide comfort</Title3>
              <Paragraph>
                Young building apprentices learn valuable skills constructing
                Ryhaven Trust cabins.
              </Paragraph>
              <Button to="/recent-projects/project-3">View project</Button>
            </section>
          </Col>
          <Col sm={4}>
            <section className={styles.project}>
              <Link to="/recent-projects/project-2">
                <img className={styles.image} src={project2} alt="View project" />
              </Link>
              <Title3>Space for a growing family</Title3>
              <Paragraph>
                Home expansion provides much-needed space for a family of nine.
              </Paragraph>
              <Button to="/recent-projects/project-2">View project</Button>
            </section>
          </Col>
          <Col sm={4}>
            <section className={styles.project}>
              <Link to="/recent-projects/project-1">
                <img className={styles.image} src={project1} alt="View project" />
              </Link>
              <Title3>Long-overdue repairs to a small family home</Title3>
              <Paragraph>
                In a small two-bedroom family home, the trust has replaced rotten
                floorboards, installed a cabin and given the kitchen and bathroom
                a much-needed facelift.
              </Paragraph>
              <Button to="/recent-projects/project-1">View project</Button>
            </section>
          </Col>
        </Row>
      </Grid>
    </section>

    <section className={styles.supporters}>
      <Grid fluid className={styles.supportersInner}>
        <Row>
          <Col xs={12} className={styles.supportersHeader}>
            <Title2>Supporters</Title2>
            <Paragraph>
              Thank you to our local supporters for helping the trust carry out
              their work
            </Paragraph>
          </Col>
        </Row>
        <Row middle="xs" center="xs">
          <Col xs={4} sm={2}>
            <a href="http://www.ltmcguinness.co.nz">
              <img src={supporter1} alt="LT McGuinness" />
            </a>
          </Col>
          <Col xs={4} sm={2}>
            <a href="https://www.dulux.co.nz">
              <img src={supporter2} alt="Dulux" />
            </a>
          </Col>
          <Col xs={4} sm={2}>
            <a href="https://www.nme.co.nz">
              <img src={supporter3} alt="NME" />
            </a>
          </Col>
          <Col xs={4} sm={2}>
            <a href="http://www.geeves.co.nz">
              <img src={supporter4} alt="Geeves Scaffolding" />
            </a>
          </Col>
          <Col xs={4} sm={2}>
            <a href="https://www.carters.co.nz">
              <img src={supporter5} alt="Carters" />
            </a>
          </Col>
          <Col xs={4} sm={2}>
            <a href="https://www.metalcraftgroup.co.nz">
              <img src={supporter6} alt="Metalcraft Roofing" />
            </a>
          </Col>
        </Row>
      </Grid>
    </section>
  </Layout>
)

export default Page
